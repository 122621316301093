import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null, // for user object
  userToken: null, // for storing the JWT
  selectedOrganization: null,
  isLogin: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.userToken = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload.user;
      state.selectedOrganization = action.payload.org;
      state.isLogin = true;
    },
    logout: (state, action) => {
      state.user = null;
      state.userToken = null;
      state.selectedOrganization = null;
      state.isLogin = false;
    },
  },
  extraReducers: {},
});
export const { setToken, setUser, logout } = authSlice.actions;

export default authSlice.reducer;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const organizationApi = createApi({
  reducerPath: "organization",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/organization`,
    jsonContentType: "application/json",
  }),
  endpoints: (builder) => ({
    getOrganizationData: builder.query({
      query: (state) => ({
        url: `/`,
        headers: {
          authorization: state?.userToken,
          orgid: state?.selectedOrganization,
        },
      }),
    }),

    editOrganizationMission: builder.mutation({
      query: (payload) => ({
        url: `/mission`,
        method: "put",
        body: payload.body,
        headers: {
          authorization: payload.state?.userToken,
          orgid: payload.state?.selectedOrganization,
          jsonContentType: "multipart/form-data",
        },
      }),
    }),
    editOrganizationVision: builder.mutation({
      query: (payload) => ({
        url: `/vision`,
        method: "put",
        body: payload.body,
        headers: {
          authorization: payload.state?.userToken,
          orgid: payload.state?.selectedOrganization,
          jsonContentType: "multipart/form-data",
        },
      }),
    }),
    editOrganizationValues: builder.mutation({
      query: (payload) => ({
        url: `/values`,
        method: "put",
        body: payload.body,
        headers: {
          authorization: payload.state?.userToken,
          orgid: payload.state?.selectedOrganization,
        },
      }),
    }),
  }),
});

export const {
  useGetOrganizationDataQuery,
  useEditOrganizationMissionMutation,
  useEditOrganizationVisionMutation,
  useEditOrganizationValuesMutation,
} = organizationApi;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const eventApi = createApi({
  reducerPath: "eventApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/event`,
    jsonContentType: "application/json",
  }),
  endpoints: (builder) => ({
    getEvents: builder.query({
      query: (payload) => ({
        url: `/${payload.type}/${payload.id}`,
      }),
    }),
    getEventsDate: builder.query({
      query: (payload) => ({
        url: `/date/${payload.type}/${payload.id}?${payload.query}`,
      }),
    }),
    getEventsByArray: builder.mutation({
      query: (payload) => ({
        url: `/`,
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const { useGetEventsQuery, useGetEventsDateQuery, useGetEventsByArrayMutation } = eventApi;

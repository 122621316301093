import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const historyApi = createApi({
  reducerPath: "historyApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/history`,
    jsonContentType: "application/json",
  }),
  endpoints: (builder) => ({
    getHistory: builder.query({
      query: (state) => ({
        url: `/${state.id}`,
        headers: {
          authorization: state?.userToken,
          orgid: state?.selectedOrganization,
        },
      }),
    }),
  }),
});

export const { useGetHistoryQuery } = historyApi;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const teamApi = createApi({
  reducerPath: "team",
  tagTypes: "team",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/team`,
    jsonContentType: "application/json",
  }),

  endpoints: (builder) => ({
    getAllTeams: builder.query({
      query: (state) => ({
        url: `/`,
        headers: {
          authorization: state?.userToken,
          orgid: state?.selectedOrganization,
        },
      }),
      providesTags: ["team"],
    }),
    getAllTeamMembers: builder.query({
      query: (payload) => ({
        url: `/team/members`,
        body: { teamIDs: payload.teamIDs },
        method: "put",
        headers: {
          authorization: payload?.userToken,
          orgid: payload?.selectedOrganization,
        },
      }),
      providesTags: ["team"],
    }),
    getTeamByObjecitve: builder.query({
      query: (payload) => ({
        url: `/objective/${payload.id}`,
        headers: {
          authorization: payload?.userToken,
          orgid: payload?.selectedOrganization,
        },
      }),
      providesTags: ["team"],
    }),
    getTeamByID: builder.query({
      query: (state) => ({
        url: `/${state.id}`,
        headers: {
          authorization: state?.userToken,
          orgid: state?.selectedOrganization,
        },
      }),
      providesTags: ["team"],
    }),
    createTeam: builder.mutation({
      query: (payload) => ({
        url: `/`,
        body: payload.body,
        method: "post",
        headers: {
          authorization: payload.state?.userToken,
          orgid: payload.state?.selectedOrganization,
        },
      }),
      invalidatesTags: ["team"],
    }),
    updateTeam: builder.mutation({
      query: (payload) => ({
        url: `/update/${payload.id}`,
        body: payload.body,
        method: "put",
        headers: {
          authorization: payload.state?.userToken,
          orgid: payload.state?.selectedOrganization,
          jsonContentType: "multipart/form-data",
        },
      }),
    }),
    updateTeamMission: builder.mutation({
      query: (payload) => ({
        url: `/mission/${payload.id}`,
        body: payload.body,
        method: "put",
        headers: {
          authorization: payload.state?.userToken,
          orgid: payload.state?.selectedOrganization,
          jsonContentType: "multipart/form-data",
        },
      }),
    }),
    updateTeamVision: builder.mutation({
      query: (payload) => ({
        url: `/vision/${payload.id}`,
        body: payload.body,
        method: "put",
        headers: {
          authorization: payload.state?.userToken,
          orgid: payload.state?.selectedOrganization,
          jsonContentType: "multipart/form-data",
        },
      }),
    }),
    editTeamMission: builder.mutation({
      query: ({ body, id }) => ({
        url: `/mission/${id}`,
        method: "put",
        body,
      }),
      invalidatesTags: ["team"],
    }),
    editTeamValues: builder.mutation({
      query: ({ body, id }) => ({
        url: `/values/${id}`,
        method: "put",
        body,
      }),
      invalidatesTags: ["team"],
    }),
    editTeamVision: builder.mutation({
      query: ({ body, id }) => {
        return {
          url: `/vision/${id}`,
          method: "put",
          body,
        };
      },
      invalidatesTags: ["team"],
    }),
    deleteTeam: builder.mutation({
      query: (id) => {
        return {
          url: `/${id}`,
          method: "delete",
        };
      },
      invalidatesTags: ["team"],
    }),
    addTeamMember: builder.mutation({
      query: (state) => {
        return {
          url: `/member/add`,
          headers: {
            authorization: state?.userToken,
            orgid: state?.selectedOrganization,
          },
          method: "POST",
          body: state.body,
        };
      },
      invalidatesTags: ["team"],
    }),
    deleteTeamMember: builder.mutation({
      query: (state) => {
        return {
          url: `/member/delete`,
          headers: {
            authorization: state?.userToken,
            orgid: state?.selectedOrganization,
          },
          method: "put",
          body: state.body,
        };
      },
      invalidatesTags: ["team"],
    }),
    changeTeamPinStatus: builder.mutation({
      query: (payload) => ({
        url: `/${payload.id}`,
        method: "put",
        headers: {
          authorization: payload.state?.userToken,
          orgid: payload.state?.selectedOrganization,
        },
      }),
      invalidatesTags: ["team"],
    }),
  }),
});

export const {
  useGetAllTeamsQuery,
  useCreateTeamMutation,
  useUpdateTeamMutation,
  useUpdateTeamMissionMutation,
  useUpdateTeamVisionMutation,
  useDeleteTeamMutation,
  useChangeTeamPinStatusMutation,
  useGetTeamByObjecitveQuery,
  useGetTeamByIDQuery,
  useGetAllTeamMembersQuery,
  useEditTeamMissionMutation,
  useEditTeamVisionMutation,
  useEditTeamValuesMutation,
  useDeleteTeamMemberMutation,
  useAddTeamMemberMutation,
} = teamApi;

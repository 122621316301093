import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const commonApi = createApi({
  reducerPath: "common",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/common`,
    jsonContentType: "application/json",
  }),
  endpoints: (builder) => ({
    getAllDepartments: builder.query({
      query: (state) => ({
        url: "/departments",
        headers: {
          authorization: state?.userToken,
          orgid: state?.selectedOrganization,
        },
      }),
    }),
    getAllApps: builder.query({
      query: (state) => {
        if (!state?.userToken || !state?.selectedOrganization) {
          return;
        }
        return {
          url: `/apps`,
          headers: {
            authorization: state?.userToken,
            orgid: state?.selectedOrganization,
          },
        };
      },
    }),
    uploadAttachment: builder.mutation({
      query: (payload) => ({
        url: "/upload-attachment",
        method: "post",
        body: payload.body,
        headers: {
          authorization: payload.state?.userToken,
          orgid: payload.state?.selectedOrganization,
          jsonContentType: "multipart/form-data",
        },
      }),
    }),
  }),
});

export const { useGetAllDepartmentsQuery, useGetAllAppsQuery, useUploadAttachmentMutation } =
  commonApi;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const commentApi = createApi({
  reducerPath: "Comment",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/Comment`,
    jsonContentType: "application/json",
  }),
  endpoints: (builder) => ({
    getComment: builder.query({
      query: (payload) => {
        if (!payload.id) {
          return;
        }

        return {
          url: `/${payload?.id}`,
          method: "get",
          headers: {
            authorization: payload?.userToken,
            orgid: payload?.selectedOrganization,
          },
        };
      },
    }),
    // getComment: builder.query({
    //   query: (id) => `/${id}`,
    // }),
    createComment: builder.mutation({
      query: (payload) => ({
        url: `/${payload.id}`,
        method: "post",
        headers: {
          authorization: payload?.userToken,
          orgid: payload?.selectedOrganization,
        },
        body: {
          message: payload.message,
          parentCommentId: payload.parentCommentId,
        },
      }),
    }),
    editComment: builder.mutation({
      query: (payload) => {
        if (!payload.id) {
          return;
        }
        return {
          url: `/${payload.id}`,
          method: "put",
          headers: {
            authorization: payload?.userToken,
            orgid: payload?.selectedOrganization,
          },
          body: {
            message: payload.message,
          },
        };
      },
    }),
    deleteComment: builder.mutation({
      query: (payload) => {
        return {
          url: `/${payload.id}`,
          method: "delete",
          headers: {
            authorization: payload?.userToken,
            orgid: payload?.selectedOrganization,
          },
        };
      },
    }),
  }),
});

export const {
  useGetCommentQuery,
  useDeleteCommentMutation,
  useCreateCommentMutation,
  useEditCommentMutation,
} = commentApi;

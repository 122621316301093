import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const objectiveApi = createApi({
  reducerPath: "objective",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/objective`,
    jsonContentType: "application/json",
  }),
  endpoints: (builder) => ({
    getAllObjectives: builder.query({
      query: (state) => ({
        url: `/`,
        headers: {
          authorization: state?.userToken,
          orgid: state?.selectedOrganization,
        },
      }),
    }),
    getSubObjectiveDetails: builder.query({
      query: (state) => ({
        url: `/sub/${state.id}`,
        headers: {
          authorization: state?.userToken,
          orgid: state?.selectedOrganization,
        },
      }),
    }),
    getObjectiveDetails: builder.query({
      query: (state) => {
        if (!state.id) {
          return;
        }
        return {
          url: `/${state.id}`,
          headers: {
            authorization: state?.userToken,
            orgid: state?.selectedOrganization,
          },
        };
      },
    }),
    createObjective: builder.mutation({
      query: (body) => ({
        url: `/`,
        method: "post",
        body,
        headers: {
          authorization: body.state?.userToken,
          orgid: body.state?.selectedOrganization,
        },
      }),
    }),
    updateObjective: builder.mutation({
      query: (payload) => ({
        url: `/${payload.id}`,
        method: "put",
        body: payload.body,
        headers: {
          authorization: payload.state?.userToken,
          orgid: payload.state?.selectedOrganization,
        },
      }),
    }),
    updateSubObjective: builder.mutation({
      query: (payload) => ({
        url: `/sub/${payload.id}`,
        method: "put",
        body: payload.body,
        headers: {
          authorization: payload?.userToken,
          orgid: payload?.selectedOrganization,
        },
      }),
    }),
    createSubObjective: builder.mutation({
      query: (payload) => ({
        url: `/${payload.id}`,
        method: "post",
        body: payload.body,
        headers: {
          authorization: payload.state?.userToken,
          orgid: payload.state?.selectedOrganization,
        },
      }),
    }),
    updateSubObjectiveStatus: builder.mutation({
      query: (payload) => ({
        url: `/sub/status/${payload.id}`,
        method: "put",
        body: payload.body,
        headers: {
          authorization: payload.state?.userToken,
          orgid: payload.state?.selectedOrganization,
        },
      }),
    }),
    updateSubObjectiveValue: builder.mutation({
      query: (payload) => ({
        url: `/sub/value/${payload.id}`,
        method: "put",
        body: payload.body,
        headers: {
          authorization: payload?.state?.userToken,
          orgid: payload.state?.selectedOrganization,
        },
      }),
    }),
    updateTaskStatus: builder.mutation({
      query: (payload) => ({
        url: `/task/${payload.id}`,
        method: "put",
        body: payload.body,
        headers: {
          authorization: payload.state?.userToken,
          orgid: payload.state?.selectedOrganization,
        },
      }),
    }),
    deleteObjectives: builder.mutation({
      query: (id) => ({
        url: `/${id}`,
        method: "delete",
      }),
    }),
    deleteSubObjectives: builder.mutation({
      query: (id) => ({
        url: `/sub/${id}`,
        method: "delete",
      }),
    }),
  }),
});

export const {
  useCreateObjectiveMutation,
  useGetSubObjectiveDetailsQuery,
  useGetAllObjectivesQuery,
  useCreateSubObjectiveMutation,
  useUpdateSubObjectiveStatusMutation,
  useUpdateSubObjectiveValueMutation,
  useUpdateTaskStatusMutation,
  useDeleteObjectivesMutation,
  useDeleteSubObjectivesMutation,
  useUpdateObjectiveMutation,
  useUpdateSubObjectiveMutation,
  useGetObjectiveDetailsQuery,
} = objectiveApi;

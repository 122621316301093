import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const focusAreaApi = createApi({
  reducerPath: "focusArea",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/plan`,
    jsonContentType: "application/json",
  }),
  endpoints: (builder) => ({
    getAllFocusAreas: builder.query({
      query: () => `/get/focusareas`,
    }),
    getFocusArea: builder.query({
      query: (id) => `/${id}/focusarea/objectives`,
    }),
    getFocusAreaByPlans: builder.query({
      query: (id) => `/getfocus/${id}`,
    }),
    createFocusArea: builder.mutation({
      query: (state) => ({
        url: `/createfocus/${state.id}`,
        method: "post",
        headers: {
          authorization: state?.userToken,
          orgid: state?.selectedOrganization,
        },
      }),
    }),
    editFocusAreaNameColor: builder.mutation({
      query: ({ id, body }) => ({
        url: `/focusarea/${id}`,
        method: "put",
        body,
      }),
    }),
    deleteFocuArea: builder.mutation({
      query: (id) => ({
        url: `/deletefocus/${id}`,
        method: "delete",
      }),
    }),
  }),
});

export const {
  useGetFocusAreaQuery,
  useEditFocusAreaNameColorMutation,
  useCreateFocusAreaMutation,
  useDeleteFocuAreaMutation,
  useGetFocusAreaByPlansQuery,
  useLazyGetFocusAreaByPlansQuery,
  useGetAllFocusAreasQuery,
} = focusAreaApi;

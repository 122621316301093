import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const authApi = createApi({
  reducerPath: "authAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/auth`,
    jsonContentType: "application/json",
  }),
  endpoints: (builder) => ({
    login: builder.mutation({
      query: ({ email, password }) => ({
        url: "/login",
        method: "post",
        body: { email, password },
      }),
    }),
    accessOrganization: builder.query({
      query: (state) => ({
        url: "/access/organization",
        headers: {
          authorization: state?.userToken,
          orgid: state?.selectedOrganization,
        },
      }),
    }),
    verifyToken: builder.mutation({
      query: (verifyData) => {
        return {
          url: "/verifyToken",
          method: "POST",
          body: verifyData,
        };
      },
    }),
  }),
});

export const { useLoginMutation, useVerifyTokenMutation, useLazyAccessOrganizationQuery, useAccessOrganizationQuery } =
  authApi;

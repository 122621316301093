import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const userApi = createApi({
  reducerPath: "user",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/user`,
    jsonContentType: "multipart/form-data",
  }),
  endpoints: (builder) => ({
    getAllUsers: builder.query({
      query: (state) => ({
        url: "/",
        headers: {
          authorization: state?.userToken,
          orgid: state?.selectedOrganization,
        },
      }),
    }),
    createUser: builder.mutation({
      query: (payload) => ({
        url: "/",
        method: "POST",
        body: payload.body,
        headers: {
          authorization: payload?.state?.userToken,
          orgid: payload?.state?.selectedOrganization,
        },
      }),
    }),
    getUserData: builder.query({
      query: (state) => ({
        url: `/${state.uId}`,
        headers: {
          authorization: state?.userToken,
          orgid: state?.selectedOrganization,
        },
      }),
    }),
    updateUser: builder.mutation({
      query: ({ payload, id }) => ({
        url: `/${id}`,
        method: "put",
        body: payload.body,
        headers: {
          authorization: payload?.state?.userToken,
          orgid: payload?.state?.selectedOrganization,
        },
      }),
    }),
    deleteUser: builder.mutation({
      query: ({payload, id}) => ({
        url: `/${id}`,
        method: "DELETE",
        body: payload.body,
        headers: {
          authorization: payload?.state?.userToken,
          orgid: payload?.state?.selectedOrganization,
        },
      }),
    }),
    revokeUser: builder.mutation({
      query: ({payload, id}) => ({
        url: `/${id}`,
        method: "DELETE",
        body: payload.body,
        headers: {
          authorization: payload?.state?.userToken,
          orgid: payload?.state?.selectedOrganization,
        },
      }),
    }),
  }),
});

export const {
  useGetAllUsersQuery,
  useCreateUserMutation,
  useGetUserDataQuery,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useRevokeUserMutation
} = userApi;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const reportApi = createApi({
  reducerPath: "reportApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/report`,
    jsonContentType: "application/json",
  }),
  endpoints: (builder) => ({
    getAllReports: builder.query({
      query: (state) => ({
        url: "/",
        headers: {
          authorization: state?.userToken,
          orgid: state?.selectedOrganization,
        },
      }),
    }),
    getReportDetails: builder.query({
      query: (state) => ({
        url: `/${state.id}`,
        headers: {
          authorization: state?.userToken,
          orgid: state?.selectedOrganization,
        },
      }),
    }),
    createReport: builder.mutation({
      query: (state) => ({
        url: "/",
        method: "POST",
        headers: {
          authorization: state?.userToken,
          orgid: state?.selectedOrganization,
        },
      }),
    }),
    updatePinStatus: builder.mutation({
      query: (state) => ({
        url: `/${state.id}`,
        method: "PUT",
        headers: {
          authorization: state?.userToken,
          orgid: state?.selectedOrganization,
        },
      }),
    }),
    createCopyReport: builder.mutation({
      query: (state) => ({
        url: `/${state.id}`,
        method: "POST",
        headers: {
          authorization: state?.userToken,
          orgid: state?.selectedOrganization,
        },
      }),
    }),
    deleteReport: builder.mutation({
      query: (state) => ({
        url: `/${state.id}`,
        method: "DELETE",
        headers: {
          authorization: state?.userToken,
          orgid: state?.selectedOrganization,
        },
      }),
    }),
    shareReport: builder.mutation({
      query: (state) => ({
        url: `/share/${state.id}`,
        method: "PUT",
        body: state.body,
        headers: {
          authorization: state?.userToken,
          orgid: state?.selectedOrganization,
        },
      }),
    }),
    updateReport: builder.mutation({
      query: (state) => ({
        url: `/message/${state.id}`,
        method: "PUT",
        body: {
          message: state.message,
        },
        headers: {
          authorization: state?.userToken,
          orgid: state?.selectedOrganization,
        },
      }),
    }),
    updateReportName: builder.mutation({
      query: (state) => ({
        url: `/name/${state.id}`,
        method: "PUT",
        body: state.body,
        headers: {
          authorization: state?.userToken,
          orgid: state?.selectedOrganization,
        },
      }),
    }),
  }),
});

export const {
  useGetAllReportsQuery,
  useGetReportDetailsQuery,
  useCreateReportMutation,
  useUpdatePinStatusMutation,
  useCreateCopyReportMutation,
  useDeleteReportMutation,
  useShareReportMutation,
  useUpdateReportMutation,
  useUpdateReportNameMutation,
} = reportApi;

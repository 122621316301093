import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { userApi } from "./api/user.api";
import { planApi } from "./api/plan.api";
import { focusAreaApi } from "./api/focusArea.api";
import { objectiveApi } from "./api/objective.api";
import { teamApi } from "./api/team.api";
import { commonApi } from "./api/common.api";
import { authApi } from "./api/auth.api";
import { eventApi } from "./api/event.api";
import { commentApi } from "./api/comment.api";
import { historyApi } from "./api/history.api";
import { reportApi } from "./api/report.api";
import { organizationApi } from "./api/organization.api";
import authSlice from "./slice/auth.slice";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage,
};
const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    //api
    [userApi.reducerPath]: userApi.reducer,
    [planApi.reducerPath]: planApi.reducer,
    [focusAreaApi.reducerPath]: focusAreaApi.reducer,
    [objectiveApi.reducerPath]: objectiveApi.reducer,
    [teamApi.reducerPath]: teamApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [commonApi.reducerPath]: commonApi.reducer,
    [eventApi.reducerPath]: eventApi.reducer,
    [commentApi.reducerPath]: commentApi.reducer,
    [historyApi.reducerPath]: historyApi.reducer,
    [reportApi.reducerPath]: reportApi.reducer,
    [organizationApi.reducerPath]: organizationApi.reducer,
    //slice
    auth: authSlice,
  }),
);
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      userApi.middleware,
      planApi.middleware,
      focusAreaApi.middleware,
      objectiveApi.middleware,
      teamApi.middleware,
      authApi.middleware,
      commonApi.middleware,
      eventApi.middleware,
      commentApi.middleware,
      historyApi.middleware,
      reportApi.middleware,
      organizationApi.middleware,
    ]),
});

export const persistor = persistStore(store);

setupListeners(store.dispatch);

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const planApi = createApi({
  reducerPath: "plan",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/plan`,
    jsonContentType: "multipart/form-data",
  }),
  endpoints: (builder) => ({
    getPlan: builder.query({
      query: (state) => ({
        url: `/${state.id}`,
        headers: {
          authorization: state?.userToken,
          orgid: state?.selectedOrganization,
        },
      }),    
    }),
    getAllPlans: builder.query({
      query: (state) => ({
        url: "/",
        headers: {
          authorization: state?.userToken,
          orgid: state?.selectedOrganization,
        },
      }),
    }),
    createPlan: builder.mutation({
      query: (state) => ({
        url: `/`,
        method: "post",
        body: state.body,
        headers: {
          authorization: state?.userToken,
          orgid: state?.selectedOrganization,
        },
      }),
    }),
    switchPlanPinnedStatus: builder.mutation({
      query: (id) => ({
        url: `/${id}`,
        method: "put",
      }),
    }),
    updatePlan: builder.mutation({
      query: (body) => ({
        url: `/`,
        method: "put",
        body,
      }),
    }),
    editPlanMission: builder.mutation({
      query: ({ body, id }) => ({
        url: `/mission/${id}`,
        method: "put",
        body,
      }),
    }),
    editPlanValues: builder.mutation({
      query: ({ body, id }) => ({
        url: `/values/${id}`,
        method: "put",
        body,
      }),
    }),
    editPlanVision: builder.mutation({
      query: ({ body, id }) => {
        return {
          url: `/vision/${id}`,
          method: "put",
          body,
        };
      },
    }),
    deletePlan: builder.mutation({
      query: (id) => {
        return {
          url: `/${id}`,
          method: "delete",
        };
      },
    }),
  }),
});

export const {
  useGetPlanQuery,
  useGetAllPlansQuery,
  useSwitchPlanPinnedStatusMutation,
  useCreatePlanMutation,
  useEditPlanMissionMutation,
  useEditPlanVisionMutation,
  useEditPlanValuesMutation,
  useDeletePlanMutation,
  useUpdatePlanMutation,
} = planApi;
